.container {
  position: relative;
  display: inline-block;
}
.button {
  padding: 0;
  width: 50px;
  border: 0;
  background:none;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100px;
  z-index: 2;
  border: 1px solid rgba(167, 164, 164,0.2);
  background-color: #fff;
  border-radius: 5px;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown li {
  padding: 12px 14px;
  font-size: 12px;
}

.dropdown li:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  
}
.btnContainer{
  color: #000;
  min-width: 120;
  text-Transform: none;
  background-color: #dfe9fb;
  font-size: large;
}

.dropdownIcon {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 130px;
  z-index: 2;
  border: 1px solid rgba(167, 164, 164,0.2);
  background-color: #fff;
  border-radius: 5px;
}

.dropdownIcon ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdownIcon li {
  padding: 12px 14px;
  font-size: 12px;
}

.dropdownIcon li:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  
}
