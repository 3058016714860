* {
  font-family: "Arimo", sans-serif;
}

html {
  font-size: 62.5%;
}

.title {
  color: #757575;
  margin: 0;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.my-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.third {
  color: #d6ff59;
}

.secondary {
  color: #5bc7c8;
}

.primary {
  color: #ff6934;
}

.p-15 {
  padding: 15px;
}

.services > p {
  color: #757575;
  padding: 5px;
  padding-right: 10px;
}

.horario {
  margin-right: 10px;
}
.horario2 {
  margin-right: 10px;
  margin-left: 10px;
}
#prueba span {
  padding-top: 20px;
}
.error {
  color: red;
}
.clubList {
  text-decoration: none;
  width: -webkit-fill-available;
}
.mensajeNoti {
  background-color: #5bc7c8;
  color: white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  padding-top: 1px;
  width: 18px;
  height: 18px;
  font-size: 14px;
  position: absolute;
}
.mensajeNoti2 {
  background-color: #5bc7c8;
  color: white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  padding-bottom: 1px;
  width: 18px;
  height: 18px;
  font-size: 14px;
  position: absolute;
}
.ml-18 {
  margin-left: 18px;
}
.ml-25 {
  margin-left: 25px;
}
.main {
  margin-left: 200px;
  
}
@media (max-width: 599px) {
  .main {
    margin-left: 0;
  }
}
.MuiListItemButton-root.Mui-selected {
  background-color: #f9ede1 !important;
}

.css-skzu66:hover {
  border: #ffc3ae solid 1px;
  box-shadow: #ffc3ae 0px 10px 36px 0px, #ffc3ae 0px 0px 0px 1px;
}

.css-1c0byge-MuiTypography-root.MuiCardHeader-title {
  font-size: 20px;
  color: #ff6934;
}

.css-gr8ynp-MuiPaper-root-MuiCard-root:hover {
  border: #ffc3ae solid 1px;
  box-shadow: #ffc3ae 0px 10px 36px 0px, #ffc3ae 0px 0px 0px 1px;
}

